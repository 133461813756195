import fetch from "../../utils/FetchInterceptorAxios";

const SubjectService = {};

SubjectService.getSubjects = function ({ page, pageSize }) {
  return fetch({
    url: `/subjectAll/${page}/${pageSize}`,
    method: "get",
  });
};

SubjectService.addSubject = function (data) {
  return fetch({
    url: "/subject",
    method: "post",
    data: data,
  });
};

SubjectService.updateSubject = function (data) {
  return fetch({
    url: "/subject",
    method: "put",
    data: data,
  });
};

SubjectService.deleteSubject = function ({ subject_id }) {
  return fetch({
    url: `/subject/${subject_id}`,
    method: "delete",
  });
};

export default SubjectService;
