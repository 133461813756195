import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import GradientService from "services/gradients/GradientsService";
import { openNotificationWithIcon } from "utils/helpers";

export const initialState = {
  loading: false,
  gradientsList: [],
  totalCount: 0,
  tableReload: false,
};

export const getGradientListAll = createAsyncThunk(
  "gradient/getGradientListAll",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await GradientService.getGradientListAll({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const addGradient = createAsyncThunk(
  "gradient/addGradient",
  async (data, { rejectWithValue }) => {
    const { gradient_name, first_color, second_color } = data;
    try {
      const response = await GradientService.addGradient({
        gradient_name,
        first_color,
        second_color,
      });

      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateGradient = createAsyncThunk(
  "gradient/updateGradient",
  async (data, { rejectWithValue }) => {
    const { gradient_name, first_color, second_color, gradient_id } = data;
    try {
      const response = await GradientService.updateGradient({
        gradient_name,
        first_color,
        second_color,
        gradient_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteGradient = createAsyncThunk(
  "gradient/deleteGradient",
  async (data, { rejectWithValue }) => {
    const { gradient_id } = data;
    try {
      const response = await GradientService.deleteGradient({
        gradient_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const gradientSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    resetGradientList: (state) => {
      state.loading = false;
      state.gradientsList = [];
      state.totalCount = 0;
      state.tableReload = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGradientListAll.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getGradientListAll.fulfilled, (state, action) => {
        state.loading = false;
        state.gradientsList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getGradientListAll.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addGradient.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(addGradient.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(addGradient.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateGradient.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(updateGradient.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(updateGradient.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteGradient.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(deleteGradient.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(deleteGradient.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, resetGradientList } = gradientSlice.actions;

export default gradientSlice.reducer;
