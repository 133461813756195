import { notification } from "antd";

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const openNotificationWithIcon = (type, errorMessages) => {
  if (Array.isArray(errorMessages)) {
    errorMessages.forEach((errorMessage) => {
      notification[type]({
        message: capitalizeFirstLetter(type),
        description: errorMessage.msg || errorMessage,
      });
    });
  } else {
    notification[type]({
      message: capitalizeFirstLetter(type),
      description: errorMessages,
    });
  }
};

export const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

export const tailLayout = {
  wrapperCol: {
    offset: 14,
    span: 10,
  },
};
