import fetch from "../../utils/FetchInterceptorAxios";

const UserService = {};

UserService.getUsersAll = function ({ page, pageSize }) {
  return fetch({
    url: `/usersAll/${page}/${pageSize}`,
    method: "get",
  });
};

UserService.updateStatus = function (data) {
  return fetch({
    url: "/updateStatus",
    method: "put",
    data: data,
  });
};

export default UserService;
