import fetch from "../../utils/FetchInterceptorAxios";

const QuestionService = {};

QuestionService.getQuestions = function ({ page, pageSize }) {
  return fetch({
    url: `/questionsAll/${page}/${pageSize}`,
    method: "get",
  });
};

QuestionService.addQuestion = function (data) {
  return fetch({
    url: "/questions",
    method: "post",
    data: data,
  });
};

QuestionService.updateQuestion = function (data) {
  return fetch({
    url: "/questions",
    method: "put",
    data: data,
  });
};

QuestionService.deleteQuestion = function ({ question_id }) {
  return fetch({
    url: `/questions/${question_id}`,
    method: "delete",
  });
};

export default QuestionService;
