import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SubjectService from "services/subjects/SubjectService";
import { openNotificationWithIcon } from "utils/helpers";

export const initialState = {
  loading: false,
  subjectList: [],
  totalCount: 0,
  tableReload: false,
};

export const getSubjects = createAsyncThunk(
  "slice/getSubjects",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await SubjectService.getSubjects({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const addSubject = createAsyncThunk(
  "slice/addSubject",
  async (data, { rejectWithValue }) => {
    const { domain_id, subject_name, gradient_id, icon } = data;
    try {
      const response = await SubjectService.addSubject({
        domain_id,
        subject_name,
        gradient_id,
        icon,
      });

      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteSubject = createAsyncThunk(
  "slice/deleteSubject",
  async (data, { rejectWithValue }) => {
    const { subject_id } = data;
    try {
      const response = await SubjectService.deleteSubject({
        subject_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateSubject = createAsyncThunk(
  "slice/updateSubject",
  async (data, { rejectWithValue }) => {
    const { domain_id, subject_name, gradient_id, icon, subject_id } = data;
    try {
      const response = await SubjectService.updateSubject({
        domain_id,
        subject_name,
        gradient_id,
        icon,
        subject_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const subjectSlice = createSlice({
  name: "slice",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    resetSubject: (state) => {
      state.loading = false;
      state.subjectList = [];
      state.totalCount = 0;
      state.tableReload = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubjects.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getSubjects.fulfilled, (state, action) => {
        state.loading = false;
        state.subjectList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getSubjects.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addSubject.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(addSubject.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(addSubject.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateSubject.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(updateSubject.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(updateSubject.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteSubject.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(deleteSubject.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(deleteSubject.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, resetSubject } = subjectSlice.actions;

export default subjectSlice.reducer;
