import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import QuestionService from "services/questions/QuestionsService";
import { openNotificationWithIcon } from "utils/helpers";

export const initialState = {
  loading: false,
  questionList: [],
  totalCount: 0,
  tableReload: false,
};

export const getQuestion = createAsyncThunk(
  "question/getQuestion",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await QuestionService.getQuestions({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const addQuestion = createAsyncThunk(
  "question/addQuestion",
  async (data, { rejectWithValue }) => {
    const {
      subject_id,
      question,
      answer,
      gradient_id,
      content_minutes,
      positive_vote,
      negative_vote,
    } = data;
    try {
      const response = await QuestionService.addQuestion({
        subject_id,
        question,
        answer,
        gradient_id,
        content_minutes,
        positive_vote,
        negative_vote,
      });

      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async (data, { rejectWithValue }) => {
    const { question_id } = data;
    try {
      const response = await QuestionService.deleteQuestion({
        question_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "question/updateQuestion",
  async (data, { rejectWithValue }) => {
    const {
      question_id,
      subject_id,
      question,
      answer,
      gradient_id,
      content_minutes,
      positive_vote,
      negative_vote,
    } = data;
    try {
      const response = await QuestionService.updateQuestion({
        question_id,
        subject_id,
        question,
        answer,
        gradient_id,
        content_minutes,
        positive_vote,
        negative_vote,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestion.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.questionList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getQuestion.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addQuestion.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateQuestion.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteQuestion.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = questionSlice.actions;

export default questionSlice.reducer;
