import fetch from "../../utils/FetchInterceptorAxios";

const DashboardService = {};

DashboardService.getDashboardCounts = function () {
  return fetch({
    url: `/getDashboardCounts`,
    method: "get",
  });
};

export default DashboardService;
