import fetch from "../../utils/FetchInterceptorAxios";

const ContactService = {};

ContactService.getContacts = function ({ page, pageSize }) {
  console.log(`/contactAll/${page}/${pageSize}`);
  return fetch({
    url: `/contactAll/${page}/${pageSize}`,
    method: "get",
  });
};

export default ContactService;
