import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import domain from "./slices/domain/domainSlice";
import subject from "./slices/subjects/subjectSlice";
import question from "./slices/questions/questionSlice";
import contact from "./slices/contact/contactSlice";
import users from "./slices/users/usersSlice";
import gradients from "./slices/gradients/gradientsSlice";
import reports from "./slices/reports/reportsSlice";
import dashboard from "./slices/dashboard/dashboardSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    domain,
    subject,
    question,
    contact,
    users,
    gradients,
    reports,
    dashboard,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
