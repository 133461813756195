import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboards.default",
    path: `${APP_PREFIX_PATH}/dashboard`,
    component: React.lazy(() => import("views/app-views/dashboard/index")),
  },
  {
    key: "dashboard.domains",
    path: `${APP_PREFIX_PATH}/domains`,
    component: React.lazy(() => import("views/app-views/domains/index")),
  },
  {
    key: "dashboard.subjects",
    path: `${APP_PREFIX_PATH}/subjects`,
    component: React.lazy(() => import("views/app-views/subjects/index")),
  },
  {
    key: "apps-questions",
    path: `${APP_PREFIX_PATH}/questions`,
    component: React.lazy(() => import("views/app-views/questions/index")),
  },
  {
    key: "apps-gradients",
    path: `${APP_PREFIX_PATH}/gradients`,
    component: React.lazy(() => import("views/app-views/gradients/index")),
  },
  {
    key: "apps-progress",
    path: `${APP_PREFIX_PATH}/progress`,
    component: React.lazy(() =>
      import("views/app-views/progress_report/index")
    ),
  },
  {
    key: "components-general-contact",
    path: `${APP_PREFIX_PATH}/contact`,
    component: React.lazy(() => import("views/app-views/contact/index")),
  },
  {
    key: "components-general-users",
    path: `${APP_PREFIX_PATH}/users`,
    component: React.lazy(() => import("views/app-views/users/index")),
  },
];
