import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "services/users/UsersService";
import { openNotificationWithIcon } from "utils/helpers";

export const initialState = {
  loading: false,
  usersList: [],
  totalCount: 0,
  tableReload: false,
};

export const getUsersAll = createAsyncThunk(
  "users/getUsers",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await UserService.getUsersAll({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateStatus = createAsyncThunk(
  "users/updateStatus",
  async (data, { rejectWithValue }) => {
    const { userId, newStatus } = data;
    try {
      const response = await UserService.updateStatus({
        userId,
        newStatus,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAll.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getUsersAll.fulfilled, (state, action) => {
        state.loading = false;
        state.usersList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getUsersAll.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateStatus.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = usersSlice.actions;

export default usersSlice.reducer;
