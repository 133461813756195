import fetch from "../../utils/FetchInterceptorAxios";

const GradientService = {};

GradientService.getGradientListAll = function ({ page, pageSize }) {
  return fetch({
    url: `/gradientsAll/${page}/${pageSize}`,
    method: "get",
  });
};

GradientService.addGradient = function (data) {
  return fetch({
    url: "/gradients",
    method: "post",
    data: data,
  });
};

GradientService.updateGradient = function (data) {
  return fetch({
    url: "/gradients",
    method: "put",
    data: data,
  });
};

GradientService.deleteGradient = function ({ gradient_id }) {
  return fetch({
    url: `/gradients/${gradient_id}`,
    method: "delete",
  });
};

export default GradientService;
