import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DomainService from "services/domain/DomainService";
import { openNotificationWithIcon } from "utils/helpers";
export const initialState = {
  loading: false,
  domainsList: [],
  totalCount: 0,
  tableReload: false,
};

export const getDomainsList = createAsyncThunk(
  "domain/getDomainsList",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await DomainService.getDomainsList({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const addDomainsList = createAsyncThunk(
  "domain/addDomainsList",
  async (data, { rejectWithValue }) => {
    console.log("data");
    console.log(data);
    const { domain_name, icon } = data;
    try {
      const response = await DomainService.addDoamin({
        domain_name,
        icon,
      });

      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteDomain = createAsyncThunk(
  "domain/deleteDomain",
  async (data, { rejectWithValue }) => {
    const { domain_id } = data;
    try {
      const response = await DomainService.deleteDomain({
        domain_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const updateDomain = createAsyncThunk(
  "domain/updateDomain",
  async (data, { rejectWithValue }) => {
    const { domain_name, icon, domain_id } = data;
    try {
      const response = await DomainService.updateDomain({
        domain_name,
        icon,
        domain_id,
      });
      openNotificationWithIcon(response.data.type, response.data.message);
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const domainSlice = createSlice({
  name: "domain",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },

    resetDomainList: (state) => {
      state.loading = false;
      state.domainsList = [];
      state.totalCount = 0;
      state.tableReload = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDomainsList.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getDomainsList.fulfilled, (state, action) => {
        state.loading = false;
        state.domainsList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getDomainsList.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(addDomainsList.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(addDomainsList.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(addDomainsList.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateDomain.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(updateDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(updateDomain.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteDomain.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(deleteDomain.fulfilled, (state, action) => {
        state.loading = false;
        state.tableReload = true;
      })
      .addCase(deleteDomain.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading, resetDomainList } = domainSlice.actions;

export default domainSlice.reducer;
