import fetch from "../../utils/FetchInterceptorAxios";

const ReportService = {};

ReportService.getServiceListAll = function ({ page, pageSize }) {
  return fetch({
    url: `/progressReportAll/${page}/${pageSize}`,
    method: "get",
  });
};

export default ReportService;
