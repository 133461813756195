import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ReportService from "services/reports/ReportService";
import { openNotificationWithIcon } from "utils/helpers";

export const initialState = {
  loading: false,
  reportsList: [],
  totalCount: 0,
  tableReload: false,
};

export const getServiceListAll = createAsyncThunk(
  "reports/getServiceListAll",
  async (data, { rejectWithValue }) => {
    const { page, pageSize } = data;
    try {
      const response = await ReportService.getServiceListAll({
        page,
        pageSize,
      });
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceListAll.pending, (state) => {
        state.loading = true;
        state.tableReload = false;
      })
      .addCase(getServiceListAll.fulfilled, (state, action) => {
        state.loading = false;
        state.reportsList = action.payload.data.data;
        state.totalCount = action.payload.data.totalCount;
        state.tableReload = false;
      })
      .addCase(getServiceListAll.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = reportSlice.actions;

export default reportSlice.reducer;
