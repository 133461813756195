import fetch from "../../utils/FetchInterceptorAxios";

const DomainService = {};

DomainService.getDomainsList = function ({ page, pageSize }) {
  return fetch({
    url: `/domain_all/${page}/${pageSize}`,
    method: "get",
  });
};

DomainService.addDoamin = function (data) {
  return fetch({
    url: "/domain",
    method: "post",
    data: data,
  });
};

DomainService.updateDomain = function (data) {
  return fetch({
    url: "/domain",
    method: "put",
    data: data,
  });
};

DomainService.deleteDomain = function ({ domain_id }) {
  return fetch({
    url: `/domain/${domain_id}`,
    method: "delete",
  });
};

export default DomainService;
