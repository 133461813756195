import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DashboardService from "services/dashboard/DashboardService";
import { openNotificationWithIcon } from "utils/helpers";
export const initialState = {
  loading: false,
  dashboardCounts: null,
};

export const getDashboardCounts = createAsyncThunk(
  "dashboard/getDashboardCounts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await DashboardService.getDashboardCounts();
      return response;
    } catch (err) {
      const errorMessage = err.response?.data?.error || ["Error"];
      openNotificationWithIcon("error", errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardCounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardCounts = action.payload.data.data;
      })
      .addCase(getDashboardCounts.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { showLoading } = dashboardSlice.actions;

export default dashboardSlice.reducer;
